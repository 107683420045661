import PropTypes from "prop-types"
import React from "react"

import Navbar from "../components/nav/navbar"

const Header = ({pathname}) => (
  <header>
    <Navbar pathname={pathname}/>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header