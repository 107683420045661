import React, { useEffect } from "react"
import UpworkLogo from "../images/upwork.png"
import M from "materialize-css"

const FixedActionButton = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".fixed-action-btn")
    let options = {
      hoverEnabled: false,
    }
    M.FloatingActionButton.init(elems, options)
  })

  return (
    <div className="fixed-action-btn click-to-toggle">
      <span className="btn-floating btn-large cg-blue">
        <i className="eggshell-text large fa fa-at"></i>
      </span>
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/in/nsmedira/"
            className="blue darken-2 btn-floating btn-large"
          >
            <i
              className="large fab fa-linkedin-in"
              aria-label="Link to LinkedIn profile"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/nsmedira"
            className="black darken-2 btn-floating btn-large"
          >
            <i
              className="large fa fa-github"
              aria-label="Link to GitHub profile"
            ></i>
          </a>
        </li>
        <li>
          <a
            href="mailto:nick@nicksmedira.com?subject=I would like more information!"
            className="cg-blue btn-floating btn-large"
            aria-label="Link to send email to nick@nicksmedira.com"
          >
            <i className="large fa fa-envelope"></i>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default FixedActionButton
