// PACKAGES
import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'

// COMPONENTS
import Header from "./header"
import FixedActionButton from "./fixed-action-button"

// STYLESHEETS
import '../styles/materialize.css'
import "../styles/layout.css"

const Layout = ({ children, location }) => {

  return (
    <>
      <Helmet>
        <script src="https://kit.fontawesome.com/db12aaf756.js" crossorigin="anonymous"></script>
      </Helmet>
      <Header pathname={location.pathname}/>
      <div className="main-div">
        <main>
          {children}
        </main>
      </div>
      <FixedActionButton />
      {/* <footer className="fixed-footer nav-text eggshell-text">
        <p style={{marginLeft: "2rem"}}>© {new Date().getFullYear()}, NAS Solutions, LLC</p>
      </footer> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
