import React from "react"
import NavSegment from "./nav_segment"

const NavList = ({ pathname }) => {
  const tabs = [
    {
      url: "/",
      label: "Home",
    },
    // TODO: re-enable when ready to publish
    {
      url: "/portfolio",
      label: "Portfolio",
    },
    {
      url: "/skills",
      label: "Skills",
    },
    {
      url: "/blog",
      label: "Blog",
    },
    {
      url: "/about",
      label: "About",
    },
  ]

  return (
    <div>
      {tabs.map((tab, i) => {
        if (
          process.env.GATSBY_IS_UNDER_CONSTRUCTION === "true" &&
          tab.url !== "/"
        ) {
          return null
        }

        return (
          <NavSegment
            key={i}
            url={tab.url}
            label={tab.label}
            pathname={pathname}
          />
        )
      })}
    </div>
  )
}

export default NavList
